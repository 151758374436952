import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import IntroCopy from "../../components/IntroCopy";
import FullWidthInfo from "../../components/FullWidthInfo";
import ImageGallery from "../../components/ImageGallery";
import ImageLockup from "../../components/ImageLockup";

const RoofingProjectsPage = ({ data }) => (
  <Layout>
    <main>
      <SEO title="Portfolio - Roof, Windows, Projects and Repairs" />
      <IntroCopy
        headline="Roof, Windows, Projects and Repairs"
        body="Our staff are skilled in the fitting of many brands of window and sun pipes, spreading natural light into previously poorly lit areas. As well as larger jobs our skilled tradesmen are equally adept at tackling smaller repairs. From tile repairs to more complicated problems our staffs experience is invaluable tracking down the source of leaks and refreshing the look of your property."
        topPadding
      />
      <ImageGallery
        images={data.images.nodes.filter(image => image.name === "1")}
      />
      <FullWidthInfo
        headline="Roof Windows"
        body="Velux are the market leader in roof windows and sun pipes and act as an excellent way of spreading natural light into a previously dimly lit room.

        Here we were called to a poorly installed group of windows. With the help of velux we managed to order the correct flashing kit and reinstall the original windows."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "2")}
        right={data.images.nodes.filter(image => image.name === "3")}
        withLabels
      />
      <FullWidthInfo
        headline="Sun Tunnels"
        body="Sun Pipes are a way of bringing natural light into a room where a roof window is not an option. Using a glass roof flashing and super reflective tunnel, the sun tunnel reflects light into a poorly lit area of a room. Our staff are skilled in placing the sun tunnel in a position to guarantee the best results."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "4")}
        right={data.images.nodes.filter(image => image.name === "5")}
      />
      <FullWidthInfo
        headline="Dorma Transformation"
        body="Here we were called to a property with a Tudor effect dorma. The timberwork was rotten and the customer no longer liked the appearance. The customer decided on the appearance that they wished to replicate and we set about achieving it. See below for the results."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "6")}
        right={data.images.nodes.filter(image => image.name === "7")}
        withLabels
      />
      <FullWidthInfo
        headline="Boundary Wall"
        body="Here we were called to a property with roof topped wall. The construction of the wall meant that all the tiles were mortared on and the budget dictated we couldn't strip it off and start again. The best option was to add mortar dye to a mix of mortar recreate the appearance of tiles using coloured mortar. Both we and the customer were extremely happy with the results."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "8")}
        right={data.images.nodes.filter(image => image.name === "9")}
        withLabels
      />
    </main>
  </Layout>
);

export const query = graphql`
  query RoofingProjectsImages {
    images: allFile(
      filter: { relativeDirectory: { eq: "portfolio/roofing-projects" } }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default RoofingProjectsPage;
